import { useMsal } from "@azure/msal-react";
import style from "../style.module.scss";

/**
 * Component for displaying the settings overview
 */
export function SettingsOverview() {
  const { instance } = useMsal();

  /**
   * Logout the user
   */
  function logout() {
    instance.logout();
  }

  /**
   * Refresh the application
   */
  function refreshApplication() {
    // Clear Service Workers
    try {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          registrations.forEach(registration => {
            registration.unregister();
          });
        });
      }
    } catch(e){
      console.error(e);
    }

    // Clear Caches
    try {
      if ("caches" in window) {
        caches.keys().then(cacheNames => {
          cacheNames.forEach(cacheName => {
            caches.delete(cacheName);
          });
        });
      }
    } catch(e){
      console.error(e);
    }

    // Clear IndexedDB
    try {
      if ("indexedDB" in window) {
        indexedDB.databases().then(databases => {
          databases.forEach(db => {
            if (db.name) {
              indexedDB.deleteDatabase(db.name);
            }
          });
        });
      }
    } catch(e){
      console.error(e);
    }

    // Clear Local Storage
    try {
      localStorage.clear();
    } catch(e){
      console.error(e);
    }

    // Clear Session Storage
    try {
      sessionStorage.clear();
    } catch(e){
      console.error(e);
    }

    window.location.reload();
  }

  return (
    <>
      <div className={style.overviewHeader}><h1>Instellingen</h1></div>
      <h2>Acties</h2>
      <div className={style.category}>
        <button onClick={logout}>Gebruiker uitloggen</button>
        <p className={style.description}>Let op: als er een gebruiker ingelogd is op het apparaat middels Single Sign On, zal er op de achtergrond mogelijk automatisch opnieuw ingelogd worden.</p>
        <button onClick={refreshApplication}>Applicatie verversen</button>
        <p className={style.description}>Ververs alle data en informatie, gebruik deze knop als OIV niet goed lijkt te werken</p>
      </div>
    </>
  );
}